import { lazy, Suspense, useLayoutEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import LoadingSuspense from '@/components/atoms/LoadingSuspense.tsx';
import HomeIcon from '@/components/icons/common/HomeIcon.tsx';
import SearchBubbleIcon from '@/components/icons/common/SearchBubbleIcon.tsx';
import NotFoundPage from '@/components/organisms/NotFoundPage.tsx';
import HomePage from '@/modules/home/pages/HomePage.tsx';

import './assets/theme/global.scss';

setupIonicReact({ mode: 'ios' });

const StorePage = lazy(() => import('@/modules/stores/pages/StorePage.tsx'));
const SearchesPage = lazy(() => import('@/modules/searches/pages/SearchesPage.tsx'));
const SearchPage = lazy(() => import('@/modules/searches/pages/SearchPage.tsx'));
const OfferPage = lazy(() => import('@/modules/offers/pages/OfferPage.tsx'));
const StoreProductPage = lazy(() => import('@/modules/products/pages/ProductPage.tsx'));

export default function App() {
  const [role, setRole] = useState('section');
  useLayoutEffect(() => {
    setTimeout(() => {
      setRole('navigation');
    }, 50);
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet animated>
            <Route component={NotFoundPage} exact path="*" />

            <Redirect from="/requests*" to="/searches*" />
            <Redirect from="/home" to="/" />

            <Route
              exact
              path="/searches"
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <SearchesPage {...props} />
                </Suspense>
              )}
            />
            <Route
              exact
              path="/searches/:searchId"
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <SearchPage {...props} />
                </Suspense>
              )}
            />

            <Route
              exact
              path="/searches/:searchId/offer/:offerId"
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <OfferPage {...props} />
                </Suspense>
              )}
            />

            <Route
              exact
              path={[
                '/store/:storeId',
                '/searches/:searchId/store/:storeId',
                '/searches/:searchId/offer/:offerId/store/:storeId',
              ]}
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <StorePage {...props} />
                </Suspense>
              )}
            />

            <Route
              exact
              path={[
                '/store/:storeId/product/:productId',
                '/searches/:searchId/store/:storeId/product/:productId',
                '/searches/:searchId/offer/:offerId/store/:storeId/product/:productId',
              ]}
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <StoreProductPage {...props} />
                </Suspense>
              )}
            />

            <Route component={HomePage} exact path="/" />
          </IonRouterOutlet>

          <IonTabBar className="flex-row-reverse lg:py-1" mode="md" role={role} slot="bottom">
            <IonTabButton
              aria-label="Request History"
              className="text-[#21252947] [&.tab-selected]:text-gobazar-400"
              href="/searches"
              // @ts-ignore
              role="tablist"
              tab="/searches"
            >
              <SearchBubbleIcon className="h-9 w-9 lg:h-11 lg:w-11" />
            </IonTabButton>

            <IonTabButton
              aria-label="GoBazar"
              className="text-[#21252947] [&.tab-selected]:text-gobazar-400"
              href="/"
              // @ts-ignore
              role="tablist"
              tab="home"
            >
              <HomeIcon className="h-9 w-9 lg:h-11 lg:w-11" />
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}
